module.exports = [{
      plugin: require('../node_modules/@weknow/gatsby-remark-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-135362415-1","anonymize":true,"respectDNT":true,"cookieDomain":"alexanderwolf.tech","head":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
